import React from "react"
import Layout from "../components/Layout"

export default function downloads() {
  return (
    <Layout>
      <div className="text-center">
        <h1>Download Center</h1>
        <table className="m-auto border-collapse border-2 border-black">
          <thead>
            <tr>
              <th className="border-black border-2 px-20">Subject</th>
              <th className="border-black border-2 px-20">Download</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-black border-2">
              <td>Signs with text, location</td>
              <td>
                <a href="/dls/signs.zip" download="">
                  Download (~1.4MB)
                </a>
              </td>
            </tr>
            <tr className="border-black border-2">
              <td>Map Art as png and location</td>
              <td>
                <a
                  href="/dls/maps_as_1024x1024px_png_with_cords.zip"
                  download=""
                >
                  Download (~35.6MB)
                </a>
              </td>
            </tr>
            <tr className="border-black border-2">
              <td>Ingame Map Data</td>
              <td>
                <a href="/dls/ingame_mapdata.zip" download="">
                  Download (~33.8MB)
                </a>
              </td>
            </tr>
            <tr className="border-black border-2">
              <td>Player Data</td>
              <td>
                <a href="/dls/playerdata.zip" download="">
                  Download (~76.6MB)
                </a>
              </td>
            </tr>
            {/* <tr>
				<td>-</td>
				<td><a href='' download>Download</a> (~.MB)</td>
			</tr> */}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}
